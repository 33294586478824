import ContentHero from "@components/ContentHero"
import {
  Button,
  CircularProgress,
  Container,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { CustomTheme } from "@theme"
import * as reviews from "@sdk/review"
import { useFormik } from "formik"
import { PageProps } from "gatsby"
import { defineMessages, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React, { FC, useEffect, useState } from "react"
import {
  array,
  bind,
  either,
  isnull,
  isstring,
  obj,
  pass,
  pipe,
  predicate,
} from "tiinvo"
import * as yup from "yup"
import * as TReview from "@definitions/Review"
import { thankYouForReview } from "@routes"

export interface LeaveReviewProps extends PageProps {}

//#region i18n

const messages = defineMessages({
  formauthor: {
    defaultMessage: `Your name`,
    id: `pages.leave-review.formauthor`,
  },
  formmessage: {
    defaultMessage: `Your review`,
    id: `pages.leave-review.formmessage`,
  },
  invalidauthor: {
    defaultMessage: `Your name is required`,
    id: `pages.leave-review.invalidauthor`,
  },
  invalidmessage: {
    defaultMessage: `Your message is required`,
    id: `pages.leave-review.invalidmessage`,
  },
  invalidmessagelength: {
    defaultMessage: `Your message is too short`,
    id: `pages.leave-review.invalidmessagelength`,
  },
})

//#endregion

const usecss = makeStyles((theme: CustomTheme) => ({
  button: {
    marginTop: 20,
  },
  disclaimer: {
    opacity: 0.54,
    marginTop: theme.spacing(3),
  },
  box: {
    display: "flex",
    flexDirection: "column",
    padding: theme.custom.sectionSpacingXs,
    marginTop: theme.custom.sectionSpacingXs,
    marginBottom: theme.custom.sectionSpacingSm,
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0 14px 32px rgba(1,1,13, .1)",
    [theme.breakpoints.up("md")]: {
      padding: theme.custom.sectionSpacingSm,
      paddingTop: 48,
    },
  },
}))

export const LeaveReview: FC<LeaveReviewProps> = ({ location, navigate }) => {
  const [canadd, setcanadd] = useState(false)
  const [didload, setdidload] = useState(false)
  const [isposting, setisposting] = useState(false)
  const [didpost, setdidpost] = useState(false)
  const { formatMessage } = useIntl()
  const classes = usecss()

  const validationSchema = yup.object({
    author: yup.string().required(formatMessage(messages.invalidauthor)),
    message: yup
      .string()
      .required(formatMessage(messages.invalidmessage))
      .min(10, formatMessage(messages.invalidmessagelength)),
  })

  const params = new URLSearchParams(location.search)
  const id = params.get(`id`)
  const foldright = pipe(
    array.fromfunctions(
      pipe(obj.mapkey("review"), isnull),
      pipe(obj.mapkey("valid"), predicate.withsamevalue(true))
    ),
    array.every(predicate.withsamevalue(true)),
    setcanadd
  )
  const foldleft = pass
  const unfoldall = pipe(
    either.fold(foldleft, foldright),
    bind(setdidload, true)
  )
  const form = useFormik({
    initialValues: TReview.create(),
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (review) => {
      if (isnull(id)) {
        return
      }

      setisposting(true)

      return reviews
        .post(id, review)
        .then(pipe(either.isRight, setdidpost, bind(setisposting, false)))
    },
  })

  useEffect(() => {
    if (isstring(id)) {
      reviews.getbyid(id).then(unfoldall)
    }
  }, [id])

  if (!didload) {
    return null
  }

  if (!canadd) {
    navigate("/404")
    return null
  }

  if (didpost) {
    navigate(thankYouForReview)
    return null
  }

  return (
    <>
      <ContentHero
        title={
          <FormattedMessage
            defaultMessage="Write a Review"
            id="components.pages.leave-review.hero.title"
          />
        }
        description={
          <FormattedMessage
            defaultMessage="If you are happy with your Totenpass, please take a minute to write a review."
            id="components.pages.leave-review.hero.description"
          />
        }
      />
      <Container maxWidth="sm">
        <div className={classes.box}>
          <TextField
            error={!!form.errors.author}
            helperText={form.errors.author}
            label={formatMessage(messages.formauthor)}
            name="author"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            value={form.values.author}
          />
          <TextField
            error={!!form.errors.message}
            helperText={form.errors.message}
            label={formatMessage(messages.formmessage)}
            multiline
            rows={3}
            name="message"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            value={form.values.message}
          />
          <Button
            className={classes.button}
            color="primary"
            disabled={form.isSubmitting || !form.isValid || isposting}
            startIcon={
              (form.isSubmitting || isposting) && (
                <CircularProgress
                  color="secondary"
                  size="12px"
                  variant="indeterminate"
                />
              )
            }
            onClick={form.submitForm}
            variant="contained"
          >
            <FormattedMessage
              defaultMessage="Submit your review"
              id="pages.leave-review.submit"
            />
          </Button>
          <Typography className={classes.disclaimer} variant="caption">
            <FormattedMessage
              defaultMessage="Note: both your name and your review might be published on the Totenpass' website."
              id="pages.leave-review.disclaimer000"
            />
            <br />
            <FormattedMessage
              defaultMessage="We suggest you to not enter sensitive data."
              id="pages.leave-review.disclaimer001"
            />
          </Typography>
        </div>
      </Container>
    </>
  )
}

export default LeaveReview
