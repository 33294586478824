import { str } from "tiinvo"

export interface Review {
  author: string
  message: string
}

//#region factories

export const create = (partial: Partial<Review> = {}): Review => ({
  author: partial.author ?? ``,
  message: partial.message ?? ``,
})

//#endregion

//#region mappables

export const mapauthor = (review: Review) => review.author
export const mapmessage = (review: Review) => review.message

//#endregion

//#region sortables

export const sortbyauthorasc = (review1: Review, review2: Review) =>
  str.sortasc(review1.author, review2.author)
export const sortbyauthordesc = (review1: Review, review2: Review) =>
  str.sortdesc(review1.author, review2.author)

//#endregion
